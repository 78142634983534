try {
  window.$ = window.jQuery = require('jquery');
  window.bootstrap = require('bootstrap/js/src/base-component');
  window.bootstrap = require('bootstrap/js/src/dropdown');
  window.bootstrap = require('bootstrap/js/src/tab');
  window.bootstrap = require('bootstrap/js/src/button');
  window.bootstrap = require('bootstrap/js/src/collapse');

  require('@popperjs/core');
} catch (e) {
  console.error(e);
}

import { Header } from './components/Header';
import { Aos } from './components/AOS';
import { Preloader } from './components/Preloader';
import { Slider } from './components/Slider';
import { Blog } from './components/Blog';
import { Map } from './components/Map';
import { Counter } from './components/Counter';

$(function () {
  'use strict';

  let ARES = ARES || {};

  const $document = $(document),
    $main = $('main'),
    $header = $('#top'),
    $headerTopNav = $('#top-nav'),
    $footer = $('#footer'),
    $window_h = window.innerHeight,
    $404_page = $('.uq-heading-404');

  ARES = {
    init: function () {
      this.Header.init();
      this.Slider.init();
      this.Blog.init();
      this.Map.init();
      this.Counter.init();
    },
    onLoad: function () {
      this.Aos.init();
      this.Aos.refresh();
      this.Page.init();
      this.Preloader.init();
    },
    onResize: function () {
      this.Page.init();
      this.Slider.update();
      this.Header.update();
    },
  };

  ARES.Page = {
    init: function () {
      // Sets minimum height of page if content height is not enough
      if ($window_h > $main.outerHeight()) {
        $main.css('min-height', $window_h - $header.outerHeight() - $footer.outerHeight() - ($headerTopNav.outerHeight() ?? 0));
      }

      if ($404_page.length > 0) {
        $404_page.css('min-height', $window_h - $header.outerHeight() - $footer.outerHeight() - ($headerTopNav.outerHeight() ?? 0));
      }
    },
  };

  ARES.Header = Header;
  ARES.Blog = Blog;
  ARES.Aos = Aos;
  ARES.Preloader = Preloader;
  ARES.Slider = Slider;
  ARES.Map = Map;
  ARES.Counter = Counter;

  ARES.init();
  ARES.onLoad();

  console.log('Document is ready!!');
  console.log('%cMade with ♥ by Uniqoders - https://uniqoders.com', 'color:#403c5e; font-size:36px');

  $(window).on('resize', function () {
    ARES.onResize();
  });
});
